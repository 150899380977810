<template>
  <v-app class="inspire">
    <application-bar />

    <div id="scrolling-techniques" class="body-inspire">
      <v-main style="min-height: 100vh">
        <v-container>
          <router-view />
        </v-container>
      </v-main>
    </div>

    <v-snackbar v-model="show_error_alert" :multi-line="true">
      {{ last_error }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="click_clear_errors">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Vue from "vue";
import store from "@/store";

import { mapGetters } from "vuex";
import ApplicationBar from "./components/ApplicationBar.vue";

import { LOGIN } from "@/store/actions.type";
import { CLEAR_ERRORS } from "@/store/mutations.type";

export default Vue.extend({
  components: { ApplicationBar },
  name: "App",
  computed: {
    ...mapGetters(["isLoading", "is_logged_in", "last_error"]),
    show_error_alert: function () {
      return this.last_error !== "";
    },
  },
  created() {
    if (!this.is_logged_in && this.$cookies.isKey("user_profile")) {
      store.dispatch(LOGIN);
    }
  },
  methods: {
    click_clear_errors: function () {
      store.commit(CLEAR_ERRORS);
    },
  },
});
</script>

<style>
a {
  text-decoration: none;
}

.inspire {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;
}

.body-inspire {
  grid-row: 2;
  grid-column: 1;
  background-color: rgb(18, 18, 18);
  overflow-y: auto;
}

html {
  overflow-y: auto;
}

.v-app-bar-title__content {
  width: 400px !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #263238 transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #263238;
  border-radius: 20px;
  border: 6px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #37474F;
}
</style>
