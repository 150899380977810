<template>
  <v-app-bar
    class="app-bar-inspire fill-height"
    color="#fcb69f"
    dark
    dense
    shrink-on-scroll
    extension-height="32"
    src="@/assets/banner.png"
    scroll-target="#scrolling-techniques"
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(19,84,122,.5), rgba(0,23,35,.8)"
      ></v-img>
    </template>

    <v-app-bar-title class="text-no-wrap d-flex flex-column pb-0">
      <div class="text-body">Torchship</div>
      <div class="text-subtitle-2 font-weight-thin">
        A post-apocalyptic roleplaying experience
      </div>
    </v-app-bar-title>

    <v-spacer></v-spacer>
    <v-autocomplete
      rounded
      solo
      dense
      :items="game_topics"
      placeholder="Start typing to Search"
      label="Search"
      item-title="keyword"
      item-text="keyword"
      item-value="key"
      return-object
      class="mt-2"
      style="max-width: 250px"
      single-line
      append-icon="mdi-magnify"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item
          v-bind="props"
          @click="$router.push(`/game_topic/${item.key}`)"
        >
          {{ item.keyword }}
        </v-list-item>
      </template>
    </v-autocomplete>
    <template v-if="!is_logged_in">
      <v-btn class="ma-2" @click="loginDialog = !loginDialog">Login</v-btn>
      <v-btn class="ma-2" @click="$router.push('/Signup')">Signup</v-btn>
      <login-dialog :show="loginDialog" />
    </template>
    <template v-if="is_logged_in">
      <v-menu
        bottom
        left
        offset-y
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="200"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="ma-1" v-bind="attrs" v-on="on">
            <v-badge
              class="mx-2 mt-1"
              :color="unread_notifications > 0 ? 'red' : 'green'"
              :content="
                unread_notifications > 9 ? '9+' : `${unread_notifications}`
              "
              :dot="unread_notifications == 0"
              :offset-x="unread_notifications == 0 ? 10 : 14"
              :offset-y="unread_notifications == 0 ? 10 : 18"
              bordered
            >
              <v-avatar size="48">
                <img
                  :src="
                    user_profile.profile_picture ||
                    'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'
                  "
                  :alt="user_profile.forum_name"
                />
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>
        <div style="width: 50vw">
          <v-system-bar height="30" dark color="primary">
            Notifications
            <v-spacer />
            <v-chip color="green" x-small class="ma-4">
              {{ unread_notifications }}
            </v-chip>
          </v-system-bar>
          <v-expansion-panels accordion :readonly="loading_notification">
            <v-expansion-panel
              v-for="n in notifications"
              :key="n.id"
              @click="load_notification(n)"
            >
              <v-expansion-panel-header
                expand-icon="mdi-menu-down"
                disable-icon-rotate
              >
                {{ n.subject }}
                <template v-slot:actions>
                  <v-icon color="error"> mdi-alert-circle </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-skeleton-loader
                  class="mx-auto"
                  type="card"
                  v-if="loading_notification"
                ></v-skeleton-loader>
                <template v-else>
                  {{ n.body }}
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-menu>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="ma-1" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item dense>
            <v-btn text dense @click="$router.push('/edit_profile')">
              Edit Profile
            </v-btn>
          </v-list-item>
          <v-list-item dense>
            <v-btn text dense @click="do_logout"> Logout </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-slot:extension>
      <v-tabs align-with-title dense>
        <v-tab
          v-for="nav in nav_links"
          :key="nav.link"
          @click="change_tab(nav.link)"
          >{{ nav.text }}</v-tab
        >
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import {
  FETCH_HELP_KEYWORDS,
  FETCH_USER_NOTIFICATIONS,
  FETCH_USER_NOTIFICATION,
  LOGOUT,
  LOGIN,
} from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";

import LoginDialog from "./Dialogs/LoginDialog.vue";

export default {
  name: "ApplicationBar",
  components: {
    LoginDialog,
  },
  computed: {
    ...mapGetters([
      "game_topics",
      "is_logged_in",
      "user_profile",
      "notifications",
      "unread_notifications",
    ]),
    nav_links: function () {
      let links = [
        { text: "Home", link: "/" },
        { text: "About", link: "/about" },
        { text: "Boards", link: "/boards" },
      ];
      if (this.is_logged_in) {
        links.push({ text: "Characters", link: "/characters" });
      }
      return links;
    },
  },
  data: () => ({
    loginDialog: false,
    loading_notification: false,
  }),
  async created() {
    store.commit(BEGIN_LOADING);
    if (!this.is_logged_in && this.$cookies.isKey("user_profile")) {
      await store.dispatch(LOGIN);
    }
    Promise.all([
      store.dispatch(FETCH_HELP_KEYWORDS),
      store.dispatch(FETCH_USER_NOTIFICATIONS),
    ]).then(() => {
      store.commit(END_LOADING);
    });
  },
  methods: {
    change_tab(link) {
      this.$router.push(link);
    },
    do_logout() {
      store.commit(BEGIN_LOADING);
      Promise.all([store.dispatch(LOGOUT)]).then(() => {
        store.commit(END_LOADING);
      });
    },
    load_notification(note) {
      if ("body" in note) {
        return;
      }
      this.loading_notification = true;
      store.dispatch(FETCH_USER_NOTIFICATION, note.id).then(() => {
        this.loading_notification = false;
      });
    },
  },
};
</script>

<style>
.app-bar-inspire {
  grid-row: 1;
  grid-column: 1;
}
</style>
