import Vue from "vue";
import axios from "axios";
import router from "../router/index.js";

import {
  BEGIN_LOADING,
  END_LOADING,
  CLEAR_ERRORS,
  SHOW_ERROR,
} from "@/store/mutations.type";
import {
  FETCH_HELP_KEYWORDS,
  LOGIN,
  REGISTER,
  LOGOUT,
  FETCH_USER_PROFILE,
  FETCH_GAME_TOPIC,
  SUBMIT_AVATAR_PICTURE,
  FETCH_USER_NOTIFICATIONS,
  FETCH_USER_NOTIFICATION,
  FETCH_CHARACTERS,
  FETCH_MSSP,
  JOIN_DISCORD,
  CREATE_CHARACTER,
  FORGOT_PASSWORD,
} from "@/store/actions.type";

const state = {
  alerts: [],
  notifications: [],
  unread_notifications: 0,
  isLoading: false,
  game_topics: [],
  user_profile: {},
  inspecting_user_profile: {},
  token: "",
  mssp: {},
  last_error: "",
  game_topic: {},
  characters: [],
};

const getters = {
  alerts(state) {
    return state.alerts;
  },
  isLoading(state) {
    return state.isLoading;
  },
  game_topic(state) {
    return state.game_topic;
  },
  game_topics(state) {
    return state.game_topics;
  },
  user_profile(state) {
    return state.user_profile;
  },
  is_logged_in(state) {
    return state.token != "" && state.user_profile != {};
  },
  is_admin(state) {
    try {
      return !!state.user_profile.is_admin;
    } catch {
      return false;
    }
  },
  inspecting_user_profile(state) {
    return state.inspecting_user_profile;
  },
  mssp(state) {
    return state.mssp;
  },
  notifications(state) {
    return state.notifications;
  },
  unread_notifications(state) {
    return state.unread_notifications;
  },
  last_error(state) {
    return state.last_error;
  },
  characters(state) {
    return state.characters;
  },
};

const actions = {
  [FETCH_HELP_KEYWORDS]({ commit }) {
    return Vue.axios
      .get(`https://api.torchship.org/game_topic/keywords`)
      .then((response) => {
        commit(FETCH_HELP_KEYWORDS + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        router.push({ name: "Internal Error" });
      });
  },
  [FETCH_GAME_TOPIC]({ commit }, key) {
    return Vue.axios
      .get(`https://api.torchship.org/game_topic/${key}`)
      .then((response) => {
        commit(FETCH_GAME_TOPIC + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        router.push({ name: "Internal Error" });
      });
  },
  [JOIN_DISCORD]({ commit }, code) {
    return Vue.axios
      .post(`https://api.torchship.org/auth/discord/${code}`)
      .then((response) => {
        commit(JOIN_DISCORD + "End", response.data);
      });
  },
  [LOGIN]({ commit }, auth) {
    if (Vue.$cookies.isKey("user_profile")) {
      // Retrieve session...
      const cookie = Vue.$cookies.get("user_profile");
      return Vue.axios
        .post(`https://api.torchship.org/auth`, { token: cookie.token })
        .then((response) => {
          commit(LOGIN + "End", {
            token: cookie.token,
            profile: response.data.profile,
          });
        })
        .catch(() => {
          commit(LOGOUT + "End");
        });
    }
    return Vue.axios
      .post(`https://api.torchship.org/auth/login`, auth)
      .then((response) => {
        commit(LOGIN + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        router.push({ name: "Internal Error" });
      });
  },
  [REGISTER]({ commit }, userdata) {
    return Vue.axios
      .post(`https://api.torchship.org/auth/register`, userdata)
      .then((response) => {
        commit(REGISTER + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        router.push({ name: "Internal Error" });
      });
  },
  [FORGOT_PASSWORD]({ commit }, userdata) {
    return Vue.axios
      .post(`https://api.torchship.org/auth/change-password`, userdata)
      .then((response) => {
        commit(FORGOT_PASSWORD + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        router.push({ name: "Internal Error" });
      });
  },
  [LOGOUT]({ commit }) {
    commit(LOGOUT + "End");
  },
  [FETCH_USER_PROFILE]({ commit }, user_id) {
    return Vue.axios
      .get(`https://api.torchship.org/profile/${user_id}`)
      .then((response) => {
        commit(FETCH_USER_PROFILE + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  [SUBMIT_AVATAR_PICTURE]({ commit }, formData) {
    return Vue.axios
      .post(`https://api.torchship.org/profile/avatar`, formData)
      .then((response) => {
        commit(SUBMIT_AVATAR_PICTURE + "End", response.data.profile_picture);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  [FETCH_MSSP]({ commit }) {
    return Vue.axios
      .get(`https://api.torchship.org/mssp`)
      .then((response) => {
        commit(FETCH_MSSP + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  [FETCH_USER_NOTIFICATIONS]({ commit }) {
    return Vue.axios
      .get(`https://api.torchship.org/notifications`)
      .then((response) => {
        commit(FETCH_USER_NOTIFICATIONS + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  [FETCH_USER_NOTIFICATION]({ commit }, id) {
    return Vue.axios
      .get(`https://api.torchship.org/notifications/${id}`)
      .then((response) => {
        commit(FETCH_USER_NOTIFICATION + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  [FETCH_CHARACTERS]({ commit }) {
    return Vue.axios
      .get(`https://api.torchship.org/characters`)
      .then((response) => {
        commit(FETCH_CHARACTERS + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  [CREATE_CHARACTER]({ commit }) {
    return Vue.axios
      .put(`https://api.torchship.org/characters`)
      .then((response) => {
        commit(FETCH_CHARACTERS + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const mutations = {
  clearAlerts(state) {
    state.alerts = [];
  },
  addAlert(state, alert) {
    state.alerts.push(alert);
  },
  clearAlert(state, alert) {
    state.alerts.splice(state.alerts.indexOf(alert), 1);
  },
  [BEGIN_LOADING](state) {
    state.isLoading = true;
  },
  [END_LOADING](state) {
    state.isLoading = false;
  },
  [FETCH_HELP_KEYWORDS + "End"](state, keywords) {
    state.game_topics = keywords;
  },
  [FETCH_GAME_TOPIC + "End"](state, data) {
    state.game_topic = data;
  },
  [FETCH_CHARACTERS + "End"](state, data) {
    state.characters = data;
  },
  [LOGIN + "End"](state, data) {
    state.user_profile = data.profile;
    state.token = data.token;
    Vue.$cookies.set("user_profile", data, "3d");
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
  },
  [REGISTER + "End"]() {},
  [FORGOT_PASSWORD + "End"]() {},
  [LOGOUT + "End"](state) {
    state.user_profile = {};
    state.token = "";
    Vue.$cookies.remove("user_profile");
    delete axios.defaults.headers.common["Authorization"];
  },
  [FETCH_USER_PROFILE + "End"](state, profile) {
    state.inspecting_user_profile = profile;
  },
  [SUBMIT_AVATAR_PICTURE + "End"](state, profile_picture) {
    state.user_profile.profile_picture = profile_picture;
    Vue.$cookies.set(
      "user_profile",
      { profile: state.user_profile, token: state.token },
      "3d"
    );
  },
  [FETCH_MSSP + "End"](state, mssp) {
    state.mssp = mssp;
  },
  [FETCH_USER_NOTIFICATIONS + "End"](state, notifications) {
    state.notifications = notifications.notes;
    state.unread_notifications = notifications.unread;
  },
  [FETCH_USER_NOTIFICATION + "End"](state, notification) {
    let index = state.notifications.findIndex((e) => e.id == notification.id);
    if (index == -1) {
      state.notifications.push(notification);
    } else {
      Vue.set(state.notifications, index, notification);
    }
  },
  [CLEAR_ERRORS](state) {
    state.last_error = "";
  },
  [SHOW_ERROR](state, new_error) {
    state.last_error = new_error;
  },
  [JOIN_DISCORD + "End"]() {},
};

export default {
  state,
  getters,
  actions,
  mutations,
};
