<template>
  <v-dialog v-model="show" persistent max-width="290">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>System Login</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form class="d-flex flex-column">
          <v-text-field
            label="Username or Email"
            v-model="username"
            outlined
            autocomplete="username"
            class="mt-5"
          />
          <v-text-field
            label="Password"
            type="password"
            v-model="password"
            outlined
            autocomplete="current-password"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="$emit('close')"> Abort </v-btn>
        <v-spacer />
        <v-btn color="green darken-1" text @click="doLogin">
          Authenticate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import store from "@/store";

import { LOGIN } from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";

export default Vue.extend({
  name: "LoginDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    doLogin: function () {
      store.commit(BEGIN_LOADING);
      store
        .dispatch(LOGIN, { user: this.username, password: this.password })
        .then(() => {
          this.show = false;
          store.commit(END_LOADING);
        });
    },
  },
  data: () => ({
    username: "",
    password: "",
  }),
});
</script>

<style>
@-webkit-keyframes autofill {
  0%,
  100% {
    color: inherit;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
</style>
