import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

function castRouteProps(route) {
  let result = {};
  for (const key in route.params) {
    if (!isNaN(Number(route.params[key])))
      result[key] = Number(route.params[key]);
    else result[key] = route.params[key];
  }
  return result;
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/characters",
    name: "Characters",
    component: () => import("@/views/Characters.vue"),
  },
  {
    path: "/game_topic/:topicKey",
    name: "Game Topic",
    component: () => import("@/views/GameTopic.vue"),
    props: castRouteProps,
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/views/Signup.vue"),
  },
  {
    path: "/forgot-password/:token?",
    name: "Forgot",
    component: () => import("@/views/ForgotPassword.vue"),
    props: castRouteProps,
  },
  {
    path: "/discord",
    name: "Discord",
    component: () => import("@/views/Discord.vue"),
  },
  {
    path: "/forbidden",
    name: "Access Denied",
    component: () => import("@/views/AccessDenied.vue"),
  },
  {
    path: "/error",
    name: "Internal Error",
    component: () => import("@/views/InternalError.vue"),
  },
  {
    path: "/profile/:userProfileId?",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    props: castRouteProps,
  },
  {
    path: "/edit_profile",
    name: "EditProfile",
    component: () => import("@/views/EditProfile.vue"),
  },
  {
    path: "/boards",
    name: "Boards",
    component: () => import("@/views/Boards/Main.vue"),
  },
  {
    path: "/boards/topic/:topicId?",
    name: "BoardTopic",
    component: () => import("@/views/Boards/Topic.vue"),
    props: castRouteProps,
  },
  {
    path: "/boards/thread/:threadId?",
    name: "BoardThread",
    component: () => import("@/views/Boards/Thread.vue"),
    props: castRouteProps,
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  const scroller = document.getElementById("scrolling-techniques");
  scroller.scrollTo(0, 0);
});

export default router;
