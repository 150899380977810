export const FETCH_BOARD_TOPICS = "fetchBoardTopics";
export const FETCH_BOARD_THREADS = "fetchBoardThreads";
export const FETCH_BOARD_THREAD = "fetchBoardThread";
export const FETCH_BOARD_MESSAGES = "fetchBoardMessages";

export const FETCH_HELP_KEYWORDS = "fetchHelpKeywords";

export const FETCH_GAME_TOPIC = "fetchGameTopic";

export const FETCH_USER_PROFILE = "fetchUserProfile";
export const FETCH_USER_NOTIFICATIONS = "fetchUserNotifications";
export const FETCH_USER_NOTIFICATION = "fetchUserNotification";

export const FETCH_MSSP = "fetchMSSP";

export const SUBMIT_BOARD_THREAD = "submitBoardThread";
export const SUBMIT_THREAD_MESSAGE = "submitThreadMessage";
export const SUBMIT_AVATAR_PICTURE = "submitAvatarPicture";

export const EDIT_THREAD_MESSAGE = "editThreadMessage";

export const DELETE_THREAD_MESSAGE = "deleteThreadMessage";

export const LOGIN = "login";
export const REGISTER = "register";
export const LOGOUT = "logout";
export const FORGOT_PASSWORD = "forgotPassword";

export const REFRESH_USER_PROFILE = "refreshUserProfile";
export const JOIN_DISCORD = "joinDiscord";

export const FETCH_CHARACTERS = "fetchCharacters";
export const CREATE_CHARACTER = "createCharacter";
