import Vue from "vue";
import router from "../router/index.js";
import {
  FETCH_BOARD_TOPICS,
  FETCH_BOARD_THREADS,
  FETCH_BOARD_MESSAGES,
  FETCH_BOARD_THREAD,
  SUBMIT_BOARD_THREAD,
  SUBMIT_THREAD_MESSAGE,
  EDIT_THREAD_MESSAGE,
  DELETE_THREAD_MESSAGE,
} from "./actions.type";

import { SHOW_ERROR } from "./mutations.type";

const state = {
  board_topics: [],
  threads: [],
  thread_messages: [],
};

const getters = {
  board_topics(state) {
    return state.board_topics;
  },
  board_categories(state) {
    return [...new Set(state.board_topics.map((item) => item.category))];
  },
  threads(state) {
    return state.threads;
  },
  thread_messages(state) {
    return state.thread_messages;
  },
};

const actions = {
  [FETCH_BOARD_TOPICS]({ commit }) {
    return Vue.axios
      .get(`https://api.torchship.org/board/topics`)
      .then((response) => {
        commit(FETCH_BOARD_TOPICS + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        router.push({ name: "Internal Error" });
      });
  },
  [FETCH_BOARD_THREADS]({ commit }, topic_id) {
    return Vue.axios
      .get(`https://api.torchship.org/board/threads/${topic_id}`)
      .then((response) => {
        commit(FETCH_BOARD_THREADS + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        router.push({ name: "Internal Error" });
      });
  },
  [FETCH_BOARD_THREAD]({ commit }, thread_id) {
    return Vue.axios
      .get(`https://api.torchship.org/board/thread/${thread_id}`)
      .then((response) => {
        commit(FETCH_BOARD_THREAD + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        router.push({ name: "Internal Error" });
      });
  },
  [FETCH_BOARD_MESSAGES]({ commit }, thread_id) {
    return Vue.axios
      .get(`https://api.torchship.org/board/messages/${thread_id}`)
      .then((response) => {
        commit(FETCH_BOARD_MESSAGES + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        router.push({ name: "Internal Error" });
      });
  },
  [SUBMIT_BOARD_THREAD]({ commit }, thread_info) {
    return Vue.axios
      .put(`https://api.torchship.org/board/thread`, thread_info)
      .then((response) => {
        commit(SUBMIT_BOARD_THREAD + "End", response.data);
        return response.data.thread_id;
      })
      .catch((error) => {
        console.error(error);
        commit(
          SHOW_ERROR,
          "Access denied; insufficient permissions to post here."
        );
        throw error;
      });
  },
  [SUBMIT_THREAD_MESSAGE]({ commit }, message_info) {
    return Vue.axios
      .put(`https://api.torchship.org/board/message`, message_info)
      .then((response) => {
        commit(SUBMIT_THREAD_MESSAGE + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        commit(
          SHOW_ERROR,
          "Access denied; insufficient permissions to post here."
        );
        throw error;
      });
  },
  [EDIT_THREAD_MESSAGE]({ commit }, message_info) {
    return Vue.axios
      .post(
        `https://api.torchship.org/board/messages/${message_info.message_id}`,
        message_info
      )
      .then((response) => {
        commit(SUBMIT_THREAD_MESSAGE + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  [DELETE_THREAD_MESSAGE]({ commit }, message_id) {
    return Vue.axios
      .delete(`https://api.torchship.org/board/messages/${message_id}`)
      .then((response) => {
        commit(DELETE_THREAD_MESSAGE + "End", response.data);
      })
      .catch((error) => {
        console.error(error);
        commit(
          SHOW_ERROR,
          "Access denied; insufficient permissions to post here."
        );
        throw error;
      });
  },
};

const mutations = {
  [FETCH_BOARD_TOPICS + "End"](state, topics) {
    state.board_topics = topics;
  },
  [FETCH_BOARD_THREADS + "End"](state, threads) {
    state.threads = threads;
  },
  [FETCH_BOARD_MESSAGES + "End"](state, messages) {
    state.thread_messages = messages;
  },
  [FETCH_BOARD_THREAD + "End"](state, thread) {
    state.threads = thread;
  },
  [SUBMIT_BOARD_THREAD + "End"](state) {
    state.threads = [];
  },
  [SUBMIT_THREAD_MESSAGE + "End"](state) {
    state.thread_messages = [];
  },
  [DELETE_THREAD_MESSAGE + "End"](state, message_id) {
    let message = state.thread_messages.find((item) => item.id == message_id);
    if (message !== undefined) {
      Vue.set(message, "is_deleted", true);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
