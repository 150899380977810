import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
// global register
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expire: "3d" });

Vue.config.productionTip = false;

Date.prototype.friendlyString = function () {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let hours = this.getHours();
  let ampm = "AM";
  if (hours > 12) {
    hours -= 12;
    ampm = "PM";
  }
  return `${
    months[this.getMonth()]
  } ${this.getDate()}, ${this.getFullYear()} ${hours}:${(
    "00" + this.getMinutes()
  ).slice(-2)} ${ampm}`;
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
